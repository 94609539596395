<template>
  <div class="service-location-container">
    <div>
      <div class="main-bg-color">
        <div class="header">
          <div class="logo-image-container">
            <img
              src="@/assets/images/Kerrry_Express.svg"
              alt="Kitten"
              class="logo-img"
            />
          </div>
        </div>
        <div class="content-container px-3 pb-3">
          <div>
            <p class="text-white mb-1">{{ $t("searchLocation") }}</p>
          </div>
          <div class="">
            <b-form-select
              v-model="serviceSelected"
              :options="serviceOptions"
              class="mb-3 custom-select-service"
              value-field="serviceCode"
              :text-field="getFieldByLanguage()"
              disabled-field="notEnabled"
              @change="onServiceChange"
            >
              <template #first>
                <b-form-select-option value="0" disabled>{{
                  $t("selectService")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div>
            <b-form-select
              v-model="branchSelected"
              :options="branchOptions"
              class="mb-3 custom-select-service"
              value-field="locationNo"
              :text-field="getFieldByLanguage()"
              disabled-field="notEnabled"
              @change="onBranchChange"
            >
              <template #first>
                <b-form-select-option value="0" disabled>{{
                  $t("selectLocation")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="container-map">
        <GoogleMapPanel
          ref="googleMap"
          :markers="markerList"
          :center="center"
          :locationSelected="locationSelected"
          v-on:setOpenAddressDetail="setOpenAddressDetail"
        />
      </div>
    </div>
    <ModalAddressDetail
      :address="locationSelectedData"
      :activeLanguage="activeLanguage"
      ref="modalLocationDetail"
    />
    <SidebarAddressDetail
      :address="locationSelectedData"
      :activeLanguage="activeLanguage"
      ref="sidebarLocationDetail"
    />
  </div>
</template>

<script>
import GoogleMapPanel from "@/components/service-location/GoogleMapPanel.vue";
import ModalAddressDetail from "@/components/service-location/modal/ModalAddressDetail.vue";
import SidebarAddressDetail from "@/components/service-location/sidebar/SidebarAddressDetail.vue";
import { gmapApi } from "vue2-google-maps";
export default {
  components: {
    GoogleMapPanel,
    ModalAddressDetail,
    SidebarAddressDetail
  },
  data() {
    return {
      isSelectService: false,
      isSelectBranch: false,
      isShowContent: false,
      serviceSelected: 0,
      branchSelected: 0,
      activeLanguage: "th",
      locationSelectedData: {
        text: "",
        value: null,
        position: {
          lat: 0,
          lng: 0
        }
      },
      locationSelected: {
        lat: 0,
        lng: 0
      },
      isLoading: true,
      center: {
        lat: 13.736717,
        lng: 100.523186
      },
      userData: {},
      markerList: [],
      serviceOptions: [],
      branchOptions: [],
      branchOptionsTemp: [],
      sessionId: null,
      lineProfile: {
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      serviceAndLocation: {}
    };
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  computed: {
    google: gmapApi
  },
  created: async function () {
    await this.geolocation();
    await this.getServiceAndLocation();
    this.sessionId = this.$route.query.sessionId;
    if (this.sessionId) {
      await this.getUserData();
    }
    if (this.$route.params.lang) {
      this.activeLanguage = this.$route.params.lang;
    }
  },
  mounted: function () {},
  methods: {
    getFieldByLanguage() {
      if (this.activeLanguage == "th") {
        return "name.th";
      } else {
        return "name.en";
      }
    },
    setOpenAddressDetail(locationSelectedData) {
      this.locationSelectedData = locationSelectedData;
      if (this.locationSelectedData) {
        if (window.innerWidth <= 1199) {
          this.$refs.modalLocationDetail.show();
        } else {
          this.$refs.sidebarLocationDetail.show(true);
        }
      }
    },
    onBranchChange(value) {
      let dataFilter = this.branchOptionsTemp.find(
        branch => branch.locationNo == value
      );
      this.markerList = [];
      this.markerList.push({
        text: "",
        value: "",
        position: this.center
      });
      this.markerList.push({
        text: dataFilter.name.th,
        value: dataFilter,
        position: {
          lat: parseFloat(dataFilter.latitude),
          lng: parseFloat(dataFilter.longitude)
        }
      });
      this.locationSelected = this.markerList[1].position;
    },
    onServiceChange(value) {
      let dataFilter = this.branchOptionsTemp.filter(branch => {
        let serviceList = [
          ...branch.additionalServices,
          ...branch.availableServices
        ];
        return serviceList.some(el => el.serviceCode == value);
      });
      this.branchOptions = [];
      this.branchOptions = dataFilter;
      this.markerList = [];
      this.markerList.push({
        text: "",
        value: "",
        position: this.center
      });
      this.branchOptions.forEach(element => {
        let tempMark = {
          text: element.name.th,
          value: element,
          position: {
            lat: parseFloat(element.latitude),
            lng: parseFloat(element.longitude)
          }
        };

        this.markerList.push(tempMark);
      });
      this.locationSelected = this.markerList[1].position;
    },
    getUserData: async function () {
      await this.$axios
        .get(this.$baseUrl + "/user/" + this.sessionId)
        .then(response => {
          if (response.data.result === 1) {
            this.userData = response.data.detail;
          }
        });
    },
    getServiceAndLocation: async function () {
      await this.$axios
        .post(this.$baseUrl + "/kerryApi/nearby", {
          origin: {
            latitude: 13.713492,
            longitude: 100.44031
          }
        })
        .then(response => {
          if (response.data.result === 1) {
            this.serviceAndLocation = response.data.detail;
            this.branchOptions = response.data.detail.locaiton;
            this.branchOptionsTemp = response.data.detail.locaiton;
            this.markerList.push({
              text: "",
              value: "",
              position: this.center
            });
            this.branchOptions.forEach(element => {
              let tempMark = {
                text: element.name.th,
                value: element,
                position: {
                  lat: parseFloat(element.latitude),
                  lng: parseFloat(element.longitude)
                }
              };

              this.markerList.push(tempMark);
            });

            this.getServiceList();
          }
        });
    },
    getServiceList() {
      this.serviceAndLocation.additionalServices.forEach(element => {
        this.serviceOptions.push(element);
      });
      this.serviceAndLocation.availableServices.forEach(element => {
        this.serviceOptions.push(element);
      });
    },
    toGoogleMap() {},
    geolocation: function () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-img {
  width: 80px;
  height: auto;
}
.content-bottom {
  position: fixed;
  bottom: 0;
  height: 300px;
}

@media only screen and (min-width: 1200px) {
  .service-location-container {
    max-width: 800px;
    margin: auto;
  }
}
</style>
