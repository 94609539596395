<template>
  <GmapMap
    ref="mapRef"
    :center="center"
    :zoom="15"
    map-type-id="terrain"
    style="width: auto; height: calc(100vh - 176px)"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false
    }"
  >
    <GmapMarker
      :icon="getIcon(index, m)"
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      @click="openWindow(m.position, m)"
    />
  </GmapMap>
</template>
<script>
export default {
  props: {
    center: {
      required: true,
      type: Object
    },
    markers: {
      required: true,
      type: Array
    },
    locationSelected: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      window_open: false,
      infowindow: {},
      data: {}
    };
  },
  watch: {
    locationSelected: {
      deep: true,
      handler: function () {
        this.$refs.mapRef.$mapPromise.then(map => {
          map.panTo({
            lat: this.locationSelected.lat,
            lng: this.locationSelected.lng
          });
        });
      }
    }
  },
  async mounted() {},
  methods: {
    getIcon(index, item) {
      if (index == 0) {
        return { url: require("@/assets/images/current-location.svg") };
      } else {
        if (item.value.locationGroup == "SHOP") {
          return { url: require("@/assets/images/service-location.svg") };
        } else {
          return { url: require("@/assets/images/service-location2.svg") };
        }
      }
    },
    openWindow(position, data) {
      if (data.value) {
        this.$emit("setOpenAddressDetail", data);
      }
    }
  },
  updated() {}
};
</script>

<style scoped></style>