<template>
  <vue-bottom-dialog
    :height="500"
    v-model="isShowModal"
    backgroundColor="#FFFFFF"
    overlayColor="rgb(0 0 0 / 50%)"
    indentColor="#DDDDDD"
  >
    <div>
      <div class="modal-address-content pt-2">
        <AddressDetailPanel
          :address="address"
          :activeLanguage="activeLanguage"
        />
      </div>
    </div>
  </vue-bottom-dialog>
</template>

<script>
import AddressDetailPanel from "@/components/service-location/AddressDetailPanel";
export default {
  props: {
    address: {
      required: true,
      type: Object
    },
    activeLanguage: {
      required: true,
      type: String
    }
  },
  components: {
    AddressDetailPanel
  },
  data() {
    return {
      search: "",
      isShowModal: false
    };
  },

  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.w-10 {
  width: 10%;
}
.search-input {
  border: 0;
  border-bottom: 1px solid #bcbcbc;
  border-radius: 0;
  box-shadow: none;
}
.search-input:focus {
  box-shadow: none;
}
.modal-address-content {
  height: calc(100vh - 300px);
  overflow: auto;
}
.text-postal {
  color: #838588;
}
</style>