<template>
  <div v-if="address.value">
    <div class="location-header px-3">
      <div class="">
        {{
          activeLanguage == "th" ? address.value.name.th : address.value.name.en
        }}
      </div>
      <div class="f-size-14 text-gray-1">
        <span
          >{{ address.value.distance | numeral("0,0.00") }} {{ $t("km") }}
        </span>
        <span class="ml-2 text-blue"
          >{{ $t("branchCode") }} {{ address.value.locationNo }}
        </span>
      </div>
    </div>
    <div class="location-content mt-4 f-size-14">
      <div class="px-3">
        {{
          activeLanguage == "th"
            ? address.value.address.th
            : address.value.address.en
        }}
      </div>
      <div class="pt-4 d-flex justify-content-between text-gray-1 px-3">
        <div class="w-50">
          <font-awesome-icon
            icon="clock"
            class="icon alt mr-2"
            size="sm"
          /><span>{{ $t("openHour") }}</span>
        </div>
        <div>
          <span>
            {{
              activeLanguage == "th"
                ? address.value.openHour.th
                : address.value.openHour.en
            }}</span
          >
        </div>
      </div>
      <div class="d-flex justify-content-between px-3">
        <div class="text-gray-1">
          <font-awesome-icon icon="clock" class="icon alt mr-2" size="sm" />
          <span>{{ $t("cutOffTime") }}</span>
        </div>
        <div class="main-color">
          <span> {{ address.value.cutOffTime }}</span>
        </div>
      </div>
      <div class="border-top py-4 d-flex justify-content-between mt-4 px-3">
        <a
          :href="'tel:+' + address.value.telePhone"
          class="telephone-text w-100"
        >
          <div class="border-right p-3 w-100 text-center">
            <div>
              <img
                src="@/assets/images/telephone-icon.svg"
                alt="Kitten"
                class="tel-img"
              />
            </div>
            <div class="mt-1">{{ $t("tel") }}</div>
          </div>
        </a>
        <div
          class="p-3 w-100 text-center"
          @click="onOpenGoogleMap(address.value)"
        >
          <div>
            <img
              src="@/assets/images/direction-icon.svg"
              alt="Kitten"
              class="tel-img"
            />
          </div>
          <div class="mt-1">{{ $t("direction") }}</div>
        </div>
      </div>
      <div class="py-3 px-3 bg-gray-5">{{ $t("avaliableService") }}</div>
      <div class="py-3 px-3">
        <div
          v-for="(item, index) in address.value.availableServices"
          :key="index"
          class="mt-2 mb-4"
        >
          <div class="">
            <span class="bg-orange-1 p-1 my-2 main-color">
              {{ activeLanguage == "th" ? item.name.th : item.name.en }}
            </span>
          </div>
          <div class="mt-3 text-gray-1">
            {{
              activeLanguage == "th" ? item.description.th : item.description.en
            }}
          </div>
        </div>
      </div>
      <div class="py-3 px-3 bg-gray-5">{{ $t("additionalService") }}</div>
      <div class="py-3 px-3 mb-5">
        <div
          v-for="(item, index) in address.value.additionalServices"
          :key="index"
          class="mt-2 mb-2"
        >
          <div class="">
            <span class="bg-orange-1 p-1 my-2 main-color">
              {{ activeLanguage == "th" ? item.name.th : item.name.en }}
            </span>
          </div>
          <div class="mt-3 text-gray-1">
            {{
              activeLanguage == "th" ? item.description.th : item.description.en
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      required: true,
      type: Object
    },
    activeLanguage: {
      required: true,
      type: String
    }
  },
  methods: {
    onOpenGoogleMap(position) {
      var ua = navigator.userAgent.toLowerCase();
      var isAndroid = ua.indexOf("android") > -1;
      var isIOS = ua.indexOf("ios") > -1;
      if (isAndroid) {
        window.location = `geo:${position.latitude},${position.longitude}`;
      } else if (isIOS) {
        window.location = `http://maps.apple.com/?ll=${position.latitude},${position.longitude}`;
      } else {
        window.open(
          "http://www.google.com/maps/place/" +
            position.latitude +
            "," +
            position.longitude
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.telephone-text {
  color: black !important;
}
</style>